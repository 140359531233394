import React, { FunctionComponent } from "react"
import {
  UsersIcon,
  SwitchHorizontalIcon,
  ServerIcon,
  ExclamationCircleIcon,
  CubeTransparentIcon,
  BadgeCheckIcon,
  ChartSquareBarIcon,
  BookOpenIcon,
} from "@heroicons/react/outline"
import { HeaderType, HeroIcon } from "@_types/header-type"
import Icon3DStack from "@icons/Square3Stack3DIcon.svg"
import HeaderLink from "./header-link"
import { PageMetadataObject } from "@models/page-metadata-type"

type CustomProps = {
  pageMetadata: PageMetadataObject
}

type FCWithCustomProps<P = {}> = FunctionComponent<P & CustomProps>

const TrophyIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2 h-6 w-6 flex-shrink-0"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle cx="12" cy="8" r="7" />
      <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
    </svg>
  )
}

const HeaderPageNavigation: FCWithCustomProps = ({ pageMetadata }) => {
  let headers: HeaderType[] = []

  switch (pageMetadata.page) {
    case "dashboard":
      switch (pageMetadata.pageCategory) {
        case "timeseries":
          headers.push({
            icon: ChartSquareBarIcon as HeroIcon,
            link: `/measurements/AS${pageMetadata.asn}/dashboard/timeseries/`,
            name: "Dashboard",
            active: true,
          })
          break
      }
      break
    case "measurements":
      switch (pageMetadata.pageCategory) {
        case "measurements":
          headers.push({
            icon: SwitchHorizontalIcon as HeroIcon,
            link: `/measurements/AS${pageMetadata.asn}/measurements/measurements/`,
            name: "Medições",
            active: true,
          })
          break
      }
      break
    case "ixbr":
      switch (pageMetadata.pageCategory) {
        case "connection":
          headers.push({
            icon: CubeTransparentIcon as HeroIcon,
            link: `/simet-as/AS${pageMetadata.asn}/ixbr/connection/`,
            name: "IX.br",
            active: true,
          })
          break
      }
      break
    case "compliance":
      switch (pageMetadata.pageCategory) {
        case "antispoofing":
          headers.push({
            icon: BadgeCheckIcon as HeroIcon,
            link: `/simet-as/AS${pageMetadata.asn}/compliance/antispoofing/`,
            name: "Conformidade",
            active: true,
          })
          break
      }
      break
    case "devices":
      switch (pageMetadata.pageCategory) {
        case "servers":
          headers.push({
            icon: ServerIcon as HeroIcon,
            link: `/simet-as/management/AS${pageMetadata.asn}/devices/servers/`,
            name: "Servidores",
            active: true,
          })
          break
      }
    case "users":
      switch (pageMetadata.pageCategory) {
        case "permissions":
          headers.push({
            icon: UsersIcon as HeroIcon,
            link: `/management/AS${pageMetadata.asn}/users/permissions/`,
            name: "Usuários PAS",
            active: true,
          })
          break
        default:
          break
      }
      break
    case "account":
      headers.push({
        icon: Icon3DStack as HeroIcon,
        link: `/account/autonomous-systems/`,
        name: "Sistemas Autônomos",
        active: true,
      })
      break
    case "desafio-bcop-nicbr-2024":
      switch (pageMetadata.pageCategory) {
        case "regulamento":
          headers.push({
            icon: BookOpenIcon as HeroIcon,
            link: `/desafio-bcop-nicbr-2024/AS${pageMetadata.asn}/regulamento/`,
            name: "Regulamento",
            active: true,
          })
          break
        case "ranking":
          headers.push({
            icon: TrophyIcon as HeroIcon,
            link: `/desafio-bcop-nicbr-2024/AS${pageMetadata.asn}/ranking/`,
            name: "Desafio BCOP",
            active: true,
          })
          break
        default:
          break
      }
      //   headers.push({
      //     icon: Icon3DStack as HeroIcon,
      //     link: `/premio-ipv6-nicbr-2023/implantacao-nova/`,
      //     name: "Prêmio 2",
      //     active: pageMetadata.pageCategory == "nova",
      //   })
      break
    default:
      let erro = "error"
      headers.push({
        icon: ExclamationCircleIcon as HeroIcon,
        link: "#",
        name: "Erro",
        active: true,
      })
      break
  }

  return (
    <>
      {headers?.map((res, idx) => {
        // const url = urlJoin(pageMetadata.urlBase, res.link)
        return (
          <HeaderLink
            key={idx}
            to={res.link}
            isActive={res.active}
            isInternal={true}
          >
            <>
              <res.icon className="mr-2 h-5 w-5 text-slate-600" />
              {res.name}
            </>
          </HeaderLink>
        )
      })}
    </>
  )
}
export default HeaderPageNavigation
