import React, { FunctionComponent } from "react"
import TableRow from "./table-row"
import TableHeader from "./table-header"
import { LeaderboardDataType, LeaderboardType } from "../leaderBoard-model"
import PageSpinner from "@components/shared/page-spinner"
import { AutonomousSystem } from "@models/autonomous-system"

interface Props {
  data: LeaderboardDataType[] | undefined
  loading: boolean
  leaderboard: LeaderboardType
  availableAutonoumsSystem: AutonomousSystem[] | null
}

const LeaderBoardTable: FunctionComponent<Props> = ({
  data,
  loading,
  //   leaderboard,
  availableAutonoumsSystem,
}) => {
  let tableColumns: JSX.Element[] = [
    <span className="flex"> </span>,
    <span className="flex">Participante</span>,
    <span className="flex">Pontuação</span>,
    <span className="flex leading-tight">
      Atende ao volume mínimo de medições
    </span>,
    <span className="flex leading-tight">
      Atende à instalação do servidor SIMET
    </span>,
  ]

  const tableRowsSorted = data?.sort((a, b) => a?.posicao - b?.posicao) || []
  console.log(tableRowsSorted)

  const renderAvailableAutonomousSystemByPosition = tableRowsSorted?.map(
    (row) => (
      <TableRow
        posicao={row.posicao}
        asn={row.asn}
        name={row.name}
        porcentagemAtual={row.porcentagemAtual}
        // porcentagemAnterior={row.porcentagemAnterior}
        // medicaoIPv6={row.medicaoIPv6}
        // medicaoIPv4={row.medicaoIPv4}
        // isAcrescimo={isAcrescimo}
        active={
          availableAutonoumsSystem?.findIndex(
            (autonomous) => autonomous.asn === row.asn
          ) !== -1
        }
        conformeRegulamentoMedicoes={row.conformeRegulamentoMedicoes}
        conformeRegulamentoSimetISP={row.conformeRegulamentoSimetISP}
        // porcentagemNovembro={row.porcentagemNovembro}
      />
    )
  )

  return (
    <>
      <table
        className="min-w-full border-separate table-auto w-full"
        style={{ borderSpacing: 0 }}
      >
        <TableHeader columns={tableColumns} />
        <tbody className="bg-white/25">
          {/* TODO: centralizar PageSpinner no meio da tabela */}
          {loading ? (
            <PageSpinner />
          ) : (
            <>{renderAvailableAutonomousSystemByPosition}</>
          )}
        </tbody>
      </table>
    </>
  )
}

export default LeaderBoardTable
