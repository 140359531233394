import { ElementType } from "@_types/element-type"

export const ASN_RELATIONSHIP = [
  "DONO_ASN",
  "FUNCIONARIO_ASN",
  "CONSULTOR_ASN",
] as const

export type AsnRelationship = ElementType<typeof ASN_RELATIONSHIP>

export function formatAsnRelationship(
  asnRelationship: AsnRelationship
): string {
  switch (asnRelationship) {
    case "DONO_ASN":
      return "Dono do ASN"
    case "CONSULTOR_ASN":
      return "Consultor do ASN"
    case "FUNCIONARIO_ASN":
      return "Funcionario do ASN"
    default:
      return "Desconhecido"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // let _: never = asnRelationship
  // throw new Error("Unknown AsnRelationship")
}
