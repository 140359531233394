import AsnDetailsModal from "@components/modal/asn-details-modal"
import RequireAuthentication from "@components/shared/require-authentication"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { AutonomousSystem } from "@models/autonomous-system"
import { PageMetadataObject } from "@models/page-metadata-type"
import { useKeycloak } from "@react-keycloak/web"
import { PageProps } from "gatsby"
import React, { useEffect, useState } from "react"
import { ReactElement } from "react"
import { Helmet } from "react-helmet"
import { useQuery } from "urql"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
  pageMetadata: PageMetadataObject
}

const RegulamentoPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)

  const [isOpen, setIsOpen] = useState(true)
  const [profile, setProfile] = useState<{
    nome: string
    email: string
  }>({ nome: "", email: "" })

  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  useEffect(() => {
    ;(async () => {
      const profile = await keycloak.loadUserProfile()

      const firstName = profile?.firstName ?? ""
      const lastName = profile?.lastName ?? ""
      const email = profile?.email ?? ""

      setProfile({
        nome: firstName + " " + lastName,
        email: email,
      })
    })()
  }, [keycloak])

  const [resultCheckAlreadyFilledAsnDetails, _] = useQuery<{
    checkAlreadyFilledAsnDetails: { alreadyFilledAsnDetails: boolean }
  }>({
    query: `
            query($asn: Int!, $email: String!) {
                checkAlreadyFilledAsnDetails(asn:$asn email:$email){
                    alreadyFilledAsnDetails
                }
            }
        `,
    variables: { asn: asn, email: profile.email },
    pause: profile.email == "" && asn == undefined,
  })

  const isAdmin = isKeycloakInitialized && keycloak.hasRealmRole("admin")

  const fecharDetalhesAsn =
    typeof window != "undefined" &&
    window.sessionStorage.getItem("fechar_detalhes_do_asn") == "true"
      ? true
      : false

  const setFecharDetalhesAsn = (value: "true" | "false") =>
    typeof window != "undefined" &&
    window.sessionStorage.setItem("fechar_detalhes_do_asn", value)

  return (
    <>
      <Helmet>
        <title>PAS - Regulamento do Desafio BCOP</title>
      </Helmet>
      <RequireAuthentication className="min-h-full relative">
        {asn != undefined &&
          //   !isAdmin &&
          !fecharDetalhesAsn &&
          (!resultCheckAlreadyFilledAsnDetails?.fetching ||
            !resultCheckAlreadyFilledAsnDetails?.data
              ?.checkAlreadyFilledAsnDetails.alreadyFilledAsnDetails ||
            typeof resultCheckAlreadyFilledAsnDetails?.error !=
              "undefined") && (
            <AsnDetailsModal
              isOpen={isOpen}
              nome={profile.nome}
              email={profile.email}
              asn={asn}
              onSubmit={() => {
                setFecharDetalhesAsn("true")
                setIsOpen(!isOpen)
              }}
              closeModal={() => {
                setIsOpen(!isOpen)
                setFecharDetalhesAsn("true")
              }}
            />
          )}

        <div className="pt-6 px-4 sm:px-6 lg:px-8 h-full font-normal leading-snug">
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Desafio BCOP NIC.br 2024</strong>
          </h2>
          <p className="mb-2">
            <span>
              Com o intuito de fomentar a disseminação das boas práticas
              operacionais, o NIC.br criou o "
            </span>
            <strong>Desafio BCOP NIC.br</strong>
            <span>
              ", que premiará instituições inscritas que se empenharem na
              melhoria contínua das suas redes.
            </span>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Participação</strong>
          </h2>
          <p className="mb-2">
            <span>
              Estão convidadas a participar do Desafio BCOP todas as
              instituições detentoras de registro de Sistema Autônomo.{" "}
            </span>
            <strong>Para se inscrever</strong>
            <span>, é preciso realizar os seguintes passos até </span>
            <strong>o dia 31 de Agosto</strong>
            <span>:</span>
          </p>
          <ul className="list-disc mt-4 ml-6">
            <li aria-level={1}>
              <strong>
                Criar uma conta no Portal do AS (
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://pas.nic.br/"
                  rel="noreferrer"
                >
                  https://pas.nic.br/
                </a>
                ) para o seu ASN
              </strong>
            </li>
          </ul>
          <ul className="list-disc ml-6">
            <li aria-level={1}>
              <strong>
                Instalar o Servidor SIMET (
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://pas.nic.br/docs/servidor-simet/"
                  rel="noreferrer"
                >
                  https://pas.nic.br/docs/servidor-simet/
                </a>
                ) no seu ASN ( prazo para instalar o servidor foi prorrogado
                para até o dia 30 de Setembro)
              </strong>
            </li>
          </ul>
          <p className="mb-2">&nbsp;</p>
          <p className="mb-2">
            <span>
              A instituição deverá estar adimplente em seus pagamentos no
              Registro.br, bem como no IX.br e OpenCDN, caso participem desses
              projetos em locais onde existe cobrança.
            </span>
          </p>
          <p className="mt-6">
            <strong>
              A partir do dia 1 de setembro de 2024, todos os ASes que seguirem
              estes passos estarão automaticamente participando do desafio.
            </strong>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Regras</strong>
          </h2>
          <p className="mb-2">
            <span>Para concorrer à premiação, o Sistema Autônomo </span>
            <span>
              precisa realizar medições de qualidade utilizando o Sistema de
              Medição de Tráfego Internet (SIMET), em qualquer uma das suas
              versões – Web, mobile ou Simetbox. Isso pode ser feito, por
              exemplo, estimulando seus usuários a utilizarem o SIMET, ou
              instalando roteadores com o Simetbox para seus usuários. Quanto
              mais medições realizadas detectarem o correto funcionamento das
              boas práticas, maior será a pontuação para conquistar o prêmio.
            </span>
          </p>
          <p className="mb-2">
            <span>
              O mínimo esperado de medições para participar do desafio é de{" "}
            </span>
            <strong>1.000 medições </strong>
            <span>realizadas por pelo menos </span>
            <strong>
              200 endereços IPv6 distintos durante o mês de novembro de 2024
            </strong>
            <span> e </span>
            <strong>de 1.500 medições </strong>
            <span>realizadas no período que engloba os </span>
            <strong>
              meses de setembro, outubro e novembro de 2024, por pelo menos 200
              endereços IPv6 distintos.{" "}
            </strong>
            <span>
              Os endereços IPv4 e IPv6 usados para identificar as medições da
              instituição, serão aqueles vinculados ao ASN cadastrado no PAS,
              conforme consulta em{" "}
            </span>
            <a
              target="_blank"
              className="text-blue-600 font-medium hover:underline"
              href="https://whois.registro.br/"
              rel="noreferrer"
            >
              <span>https://whois.registro.br</span>
            </a>
            <span>.</span>
          </p>
          <p className="mb-2">&nbsp;</p>
          <p className="mb-4">
            <span>
              O ranqueamento leva em consideração dois critérios mensurados em
              dois aspectos:
            </span>
          </p>
          <ul className="list-disc list-outside ml-6">
            <li aria-level={1}>
              <strong>Critério de Infraestrutura:&nbsp;</strong>
              <ul className="list-disc list-outside ml-6">
                <li aria-level={1}>
                  <strong>Implantação IPv6</strong>
                </li>
                <li aria-level={1}>
                  <strong>
                    Possuir servidor DNS recursivo próprio atendendo a sua
                    rede&nbsp;
                  </strong>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="list-disc list-outside ml-6">
            <li aria-level={1}>
              <strong>Critério de Segurança:&nbsp;</strong>
              <ul className="list-disc list-outside ml-6">
                <li aria-level={1}>
                  <strong>
                    Servidor DNS recursivo próprio - Autorização de acesso
                    (KINDNS Recomendação 2 da categoria Servidor Recursivo
                    Compartilhado, BCP140)
                  </strong>
                </li>
                <li aria-level={1}>
                  <strong>
                    Filtragem de pacotes spoofados saindo de sua rede (MANRS
                    Action 2)
                  </strong>
                </li>
              </ul>
            </li>
          </ul>
          <p className="mb-2">
            <span>
              A pontuação é composta pela taxa de implantação IPv6, da qual se
              subtrai pontos caso não sejam seguidas as boas práticas
              recomendadas, conforme detalhado pela tabela:&nbsp;
            </span>
            <span>&nbsp;&nbsp;</span>
          </p>
          <table className="border border-solid	 border-black mt-4 mb-4">
            <tbody>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black">
                  <p className="text-center">
                    <strong>Pontuação base&nbsp;</strong>
                  </p>
                </td>
                <td className="border border-solid	 border-black">
                  <p className="text-center">
                    <strong>taxa de implantação do IPv6</strong>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black" rowSpan={4}>
                  <p className="text-center">
                    <strong>Subtrações de&nbsp;DNS recursivo</strong>
                  </p>
                </td>
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <strong>-10 pontos</strong>, se <strong>não</strong> houver
                    <strong> servidor DNS recursivo próprio</strong> atendendo a
                    sua rede em <strong>IPv4</strong>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <span>
                      <strong>-5 pontos</strong>, se o servidor
                      <strong> DNS recursivo IPv4 </strong>
                      estiver <strong>respondendo</strong> solicitações feitas
                      de <strong> fora da sua rede</strong> (sem filtragem)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <span>
                      <strong>-10 pontos</strong>, se <strong>não</strong>{" "}
                      houver servidor <strong>DNS recursivo próprio</strong>{" "}
                      atendendo a sua rede em <strong>IPv6</strong>&nbsp;
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <span>
                      <strong>-5 pontos</strong>, se o servidor
                      <strong> DNS recursivo IPv6</strong> estiver respondendo
                      solicitações feitas de <strong>fora da sua rede</strong>{" "}
                      (sem filtragem)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid border-black">
                <td className="border border-solid border-black" rowSpan={2}>
                  <p className="mb-2">
                    <strong>Subtrações de&nbsp;filtragem de pacotes</strong>
                  </p>
                </td>
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <span>
                      <strong>-10 pontos</strong>, se detectado o
                      <strong> encaminhamento de pacotes IPv6 spoofado</strong>{" "}
                      saindo de sua rede
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="border border-solid	 border-black">
                <td className="border border-solid	 border-black">
                  <p className="mb-2">
                    <span>
                      <strong>-10 pontos</strong>, se detectado o
                      <strong> encaminhamento de pacotes IPv4 spoofado</strong>{" "}
                      saindo de sua rede
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mb-2">
            <span>
              As subtrações dos critérios de segurança serão aplicadas se houver
              pelo menos uma deteção da não-conformidade durante o mês de
              novembro de 2024. Setembro e outubro de 2024 são períodos de
              adaptação do provedor, em quais haverá notificação mas não a
              subtração de pontos.
            </span>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Prêmios</strong>
          </h2>
          <p className="mb-2">
            <span>
              Todas as instituições cadastradas concorrerão a dois prêmios:
            </span>
          </p>
          <ul className="list-disc list-outside ml-6">
            <li aria-level={1}>
              <span>
                Um relacionado a maior percentagem de implantação de boas
                práticas no mês de novembro
              </span>
            </li>
            <li aria-level={1}>
              <span>
                Outro relacionado à instituição que mais se empenhou na
                implantação das boas práticas durante o período de 1 de setembro
                até 1 de dezembro de 2023.
              </span>
            </li>
          </ul>
          <p className="mb-2">
            <strong>
              Ademais, todas as instituições que alcançarem 75% de adoção de
              boas práticas registrada pelo SIMET no mês de novembro, ganharão
              um certificado de honra ao mérito e serão mencionadas na
              premiação.
            </strong>
          </p>
          <p className="mb-2">&nbsp;</p>
          <p className="mb-2">
            <strong>
              Prêmio 1: Instituição com melhor ranking de implantação de boas
              práticas em novembro/2024
            </strong>
          </p>
          <p className="mb-2">
            <span>
              Nesta categoria, a medição das boas práticas será feita através da
              porcentagem de medições com IPv6 funcionando corretamente, em
              relação ao total de medições da instituição, durante o mês de
              novembro de 2024, subtraída da pontuação por não implantação das
              boas práticas selecionadas (ver quadro de regras). A instituição
              que tiver a maior pontuação total será a vencedora. Como critério
              de desempate, será considerada a quantidade absoluta de endereços
              IPv6 únicos detectados nas medições realizadas utilizando o SIMET.
            </span>
          </p>
          <p className="mb-2">
            <span>
              A instituição ganhadora receberá uma bolsa para participar do
              evento do LACNIC no segundo semestre de 2025. A bolsa consiste em
              passagens aéreas (ida e volta) e diárias de hotel. A instituição,
              na pessoa do contato administrativo designado no Registro.br, ou
              do seu representante legal, deve designar um funcionário
              contratado em regime CLT para receber esse prêmio, que não poderá
              ser cedido a terceiros.
            </span>
          </p>
          <p className="mb-2">
            <span>
              A instituição ganhadora será beneficiada com uma cota de
              patrocínio no ano de 2025 para os cursos BCOP, IX Fóruns
              Regionais, Lives Intra Rede e Semanas de Capacitação Online,
              promovidas pelo Ceptro.br. Os benefícios concedidos serão
              equivalentes aos dos demais patrocinadores.
            </span>
          </p>
          <p className="mb-2">&nbsp;</p>
          <p className="mb-2">
            <strong>
              Prêmio 2: Instituição que mais implantou as boas práticas durante
              o período do Desafio
            </strong>
          </p>
          <p className="mb-2">
            <span>
              Nesta categoria será avaliada a implantação ao longo do período do
              Desafio, que engloba os meses de setembro, outubro e novembro de
              2024.
            </span>
          </p>
          <p className="mb-2">
            <span>
              Como valor inicial, será considerada a porcentagem de implantação
              de IPv6 segundo as medições do SIMET realizadas pela instituição
              no período que engloba os meses de janeiro, fevereiro, março,
              abril, maio, junho, julho e agosto de 2024, ou seja, antes do
              início do prêmio, que detectarem a disponibilidade e correto
              funcionamento do protocolo, em comparação com o total de medições
              realizadas. Este será o valor referência para o início do desafio.
              Caso sejam detectadas um número inferior a 1.000 medições no
              período, ou menos de 100 endereços IPv6 distintos nas medições,
              será atribuído o valor de 0% de implantação de IPv6.
            </span>
          </p>
          <p className="mb-2">
            <span>
              Durante o mês de novembro, será realizada a sumarização final de
              implantação do IPv6 a partir dos dados coletados pelo SIMET, e a
              instituição que tiver a maior diferença percentual positiva de
              implantação (valor final - valor inicial), subtraída da pontuação
              por não implantação das boas práticas selecionadas (ver seção de
              regras), será a ganhadora. Como critério de desempate, será levado
              em consideração a quantidade absoluta de medições em IPv6 feitas
              durante o mês de novembro pelo SIMET.
            </span>
          </p>
          <p className="mb-2">
            <span>
              A instituição ganhadora receberá uma bolsa para participar do
              evento do LACNIC no segundo semestre de 2025, que consiste de
              passagens aéreas (ida e volta) e diárias de hotel. A instituição,
              na pessoa do contato administrativo designado no Registro.br, ou
              do seu representante legal, deve designar um funcionário
              contratado em regime CLT para receber esse prêmio, que não poderá
              ser cedido a terceiros.
            </span>
          </p>
          <p className="mb-2">
            <span>
              A instituição ganhadora será beneficiada com uma cota de
              patrocínio no ano de 2025 para os cursos BCOP, IX Fóruns
              Regionais, Lives Intra Rede e Semanas de Capacitação Online,
              promovidas pelo Ceptro.br. Os benefícios concedidos serão
              equivalentes aos dos demais patrocinadores.
            </span>
          </p>
          <p className="mb-2">
            <strong>Nota</strong>
            <span>
              : Cada instituição, identificada pelo ASN, poderá participar
              apenas uma vez do desafio, e poderá ganhar apenas um dos prêmios.
              O contato administrativo do ASN designado no Registro.br ou, em
              sua ausência, o representante legal da instituição, será contatado
              para combinar os detalhes da premiação.
            </span>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Datas</strong>
          </h2>
          <ul className="list-disc list-outside ml-6">
            <li aria-level={1}>
              <strong>
                Período de Inscrição: 29 de julho de 2024 até 31 de agosto de
                2024
              </strong>
            </li>
            <li aria-level={1}>
              <strong>
                Período de análise dos dados do prêmio 1: 1 de novembro de 2024
                até 1 de dezembro de 2024
              </strong>
            </li>
            <li aria-level={1}>
              <strong>
                Período de análise dos dados do prêmio 2: 1 de setembro de 2024
                até 1 de dezembro de 2024
              </strong>
            </li>
            <li aria-level={1}>
              <strong>
                Premiação: 13 de dezembro de 2024 (durante a Semana de
                Infraestrutura)
              </strong>
            </li>
          </ul>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Considerações</strong>
          </h2>
          <p className="mb-2">
            <span>
              Caso haja identificação de fraude do ganhador do desafio BCOP, o
              NIC.br se reservará ao direito de remover a premiação e fornecê-la
              ao segundo colocado (e se este também cometer alguma fraude, o
              prêmio irá para o terceiro colocado e assim por diante até
              encontrar um participante adequado às normas do desafio).
            </span>
          </p>
          <p className="mb-2">
            <span>
              Qualquer dúvida sobre a premiação, fique a vontade para mandar
              email{" "}
            </span>
            <span>cursosceptro@nic.br</span>
            <span> .&nbsp;</span>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Como acompanhar as medições do desafio</strong>
          </h2>
          <p className="mb-2">
            <span>O participante pode acompanhar as medições SIMET no </span>
            <strong>Portal do Sistema Autônomo (PAS)</strong>
            <span> sob o endereço </span>
            <a
              target="_blank"
              className="text-blue-600 font-medium hover:underline"
              href="https://pas.nic.br/"
              rel="noreferrer"
            >
              <span>https://pas.nic.br</span>
            </a>
            <span>
              . A partir de 1 de setembro 2024 será mostrado no portal o
            </span>
            <strong> ranking do Desafio BCOP NIC.br</strong>
            <span>
              , permitindo que o provedor compare semanalmente seu avanço e sua
              posição com os demais participantes do desafio.&nbsp;
            </span>
          </p>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Estudo para ter mais chances de ganhar</strong>
          </h2>
          <p className="mb-2">
            <span>
              Como sugestão de estudo, deixamos as seguintes referências:
            </span>
          </p>
          <ul className="list-disc list-outside ml-6">
            <li aria-level={1}>
              <span>
                Realizar o Curso Básico de IPv6 - A distância (gratuito) -{" "}
              </span>
              <a
                target="_blank"
                className="text-blue-600 font-medium hover:underline"
                href="https://cursoseventos.nic.br/curso/curso-basico-ipv6-ead/"
                rel="noreferrer"
              >
                <span>
                  https://cursoseventos.nic.br/curso/curso-basico-ipv6-ead/
                </span>
                <span>
                  <br />
                  <br />
                </span>
              </a>
            </li>
            <li aria-level={1}>
              <span>Lives Intra Rede</span>
            </li>
            <ul className="list-disc list-outside ml-6">
              <li aria-level={2}>
                <span>
                  IPv6: 25 anos de progresso e evolução da infraestrutura da
                  Internet -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://intrarede.nic.br/live-25anos-ipv6-2023/"
                  rel="noreferrer"
                >
                  <span>https://intrarede.nic.br/live-25anos-ipv6-2023/</span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>
                  Live "IPv6 e os principais erros cometidos numa implantação de
                  rede" -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://intrarede.nic.br/live-ipv6-implantacao-2022/"
                  rel="noreferrer"
                >
                  <span>
                    https://intrarede.nic.br/live-ipv6-implantacao-2022/
                  </span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>Live "IPv6: casos de sucesso" - </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://intrarede.nic.br/live-ipv6-sucesso-2021/"
                  rel="noreferrer"
                >
                  <span>https://intrarede.nic.br/live-ipv6-sucesso-2021/</span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>
                  Live "Fim do IPv4, transferência de IP e adoção do IPv6" -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://intrarede.nic.br/live-fim-do-ipv4-transferencia-de-ip-e-adocao-do-ipv6/"
                  rel="noreferrer"
                >
                  <span>
                    https://intrarede.nic.br/live-fim-do-ipv4-transferencia-de-ip-e-adocao-do-ipv6/
                  </span>
                </a>
                <span>
                  <br />
                  <br />
                </span>
              </li>
            </ul>
            <li aria-level={1}>
              <span>Podcast Camada 8</span>
            </li>
            <ul className="list-disc list-outside ml-6">
              <li aria-level={2}>
                <span>#52 - Redes IPv6 Only com Henri Alves - </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://nic.br/podcasts/camada8/episodio-52"
                  rel="noreferrer"
                >
                  <span>https://nic.br/podcasts/camada8/episodio-52</span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>
                  #41 - Medições: Como garantir a qualidade da sua Internet com
                  Holger Wiehen e Paulo Kuester -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://nic.br/podcasts/camada8/episodio-41"
                  rel="noreferrer"
                >
                  <span>https://nic.br/podcasts/camada8/episodio-41</span>
                </a>
                <span>&nbsp;&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>
                  #21 - Como e por que se tornar um Sistema Autônomo, com
                  Ricardo Patara (gerente do Registro.br) -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://www.nic.br/podcasts/camada8/episodio-21"
                  rel="noreferrer"
                >
                  <span>https://www.nic.br/podcasts/camada8/episodio-21</span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>
                  #14 - IPv6, Fim do IPv4 e CGNAT com Fernando Frediani do BPF -{" "}
                </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://www.nic.br/podcasts/camada8/episodio-14"
                  rel="noreferrer"
                >
                  <span>https://www.nic.br/podcasts/camada8/episodio-14</span>
                </a>
                <span>&nbsp;</span>
              </li>
              <li aria-level={2}>
                <span>#4 - Rumo ao IPv6 Brasil! - </span>
                <a
                  target="_blank"
                  className="text-blue-600 font-medium hover:underline"
                  href="https://www.nic.br/podcasts/camada8/episodio-4"
                  rel="noreferrer"
                >
                  <span>https://www.nic.br/podcasts/camada8/episodio-4</span>
                </a>
              </li>
            </ul>
          </ul>
          <h2 className="text-center text-xl font-bold mb-2 mt-4">
            <strong>Detalhes sobre como é feito as medições</strong>
          </h2>
          <h2 className="text-base font-bold mb-2">
            <span>Taxa de implantação IPv6</span>
          </h2>
          <p className="mb-2">
            <span>
              A taxa de implantação de IPv6 do sistema autônomo é derivada das
              medições de qualidade SIMET, podendo ser utilizado o medidor em
              qualquer uma das suas versões (Web, mobile ou SIMETBox).&nbsp;
            </span>
          </p>
          <p className="mb-2">
            <span>
              Define-se a taxa de implantação de IPv6 como a fração das medições
              com IPv6 funcional em relação ao total das medições. Para
              representar a rede do provedor, é contada somente uma medição de
              cada medidor SIMET, sem repetição. Caso haja medição repetida é
              considerada a primeira medição em IPv4 e em IPv6 no período.
            </span>
          </p>
          <h2 className="text-base font-bold mb-2 mt-4">
            <span>Servidor DNS recursivo</span>
          </h2>
          <p className="mb-2">
            <span>
              Detecta-se o servidor recursivo do usuário final com uma medição
              do medidor SIMET nas versões Web e SIMETBox. A detecção é
              implementada em um servidor autoritativo do projeto SIMET, cujo
              nome é consultado pelo medidor. O servidor registra o endereço do
              recursivo que lhe consultou.
            </span>
          </p>
          <p className="mb-2">
            <span>
              Para levar em consideração que uma fração dos usuários pode
              alterar manualmente sua configuração de servidor DNS, espera-se
              que pelo menos 80% das consultas DNS do sistema autônomo venham de
              servidor recursivo próprio. Se o valor detectado for inferior, é
              contabilizada a perda de pontos (ver quadro de regras).
            </span>
          </p>
          <p className="mb-2">
            <span>
              Para verificar se o servidor DNS recursivo é protegido por alguma
              filtragem de pacotes, é feita uma consulta DNS a partir de um
              endereço IP não pertencente ao sistema autônomo do provedor. Se a
              consulta não for bloqueada, é contabilizada perda de pontos (ver
              quadro de regras).
            </span>
          </p>
          <p className="mb-2">
            <span>
              Os testes são realizados em IPv4 e IPv6 e as respectivas perdas de
              pontos serão contabilizadas por protocolo.
            </span>
          </p>

          <h2 className="text-base font-bold mb-2 mt-2">
            <span>Filtragem de pacote spoofado</span>
          </h2>
          <p className="mb-2">
            <span>
              Detectamos se o sistema autônomo filtra pacotes spoofados, com uma
              medição originada no servidor SIMET implantado na rede do
              provedor. O servidor gera um pacote com endereço de origem
              spoofado e o envia ao servidor âncora do AS 14026 (SIMET). Caso o
              pacote seja recebido no servidor âncora, ele não foi filtrado pelo
              sistema autônomo. É contabilizada perda de pontos (ver quadro de
              regras) se pelo menos um pacote spoofado não for filtrado.&nbsp;
            </span>
          </p>
          <p className="mb-2">
            <span>
              Os testes são realizados em IPv4 e IPv6 e as respectivas perdas de
              pontos serão contabilizadas por protocolo.
            </span>
          </p>
          <p className="mb-2">&nbsp;</p>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default RegulamentoPage
