import React, { FunctionComponent } from "react"
import { LeaderboardGeralDataType, LeaderboardType } from "./leaderBoard-model"
import LeaderBoardTable from "./table"
import { useQuery } from "urql"
import { useKeycloak } from "@react-keycloak/web"
import { AutonomousSystem } from "@models/autonomous-system"
import { parseISO, add, format } from "date-fns"
import CSVReportDownload from "@components/csv-report/download"
import { DownloadIcon } from "@heroicons/react/solid"

interface Props {
  availableAutonoumsSystem: AutonomousSystem[] | null
  leaderboard: LeaderboardType
  leaderboardGeralData: LeaderboardGeralDataType[]
  isLoading: boolean
}

const headers = [
  { label: "Posição", key: "posicao" },
  { label: "Asn", key: "asn" },
  { label: "Nome Provedor", key: "name" },
  { label: "Porcentagem Atual", key: "porcentagemAtual" },
  //   { label: "Porcentagem Anterior", key: "porcentagemAnterior" },
  //   { label: "Porcentagem Novembro", key: "porcentagemNovembro" },
  //   { label: "Medição IPv6", key: "medicaoIPv6" },
  //   { label: "Medição IPv4", key: "medicaoIPv4" },
  {
    label: "Conforme Regulamento Medicoes",
    key: "conformeRegulamentoMedicoes",
  },
  {
    label: "Conforme Regulamento Simet ISP",
    key: "conformeRegulamentoSimetISP",
  },
]

const LeaderBoardGeral: FunctionComponent<Props> = ({
  availableAutonoumsSystem,
  leaderboard,
  leaderboardGeralData,
  isLoading,
}) => {
  const { initialized: isKeycloakInitialized } = useKeycloak()

  //   if (error) console.error(error)

  //   let ultimaAtualizacao = undefined
  //   let proximaAtualizacao = undefined
  //   let filenameDate = undefined

  //   if (!fetching && data?.leaderboardGeral.lastUpdate !== undefined) {
  //     ultimaAtualizacao = parseISO(data?.leaderboardGeral.lastUpdate)
  //     proximaAtualizacao = add(ultimaAtualizacao, { days: 7 })
  //     filenameDate = format(ultimaAtualizacao, "dd-MM-yyyy")
  //   }

  const formatLeaderboardTypeForReport = `Implantação ${
    leaderboard === "ACRESCIMO" ? "Nova" : "Total"
  }`

  const filenameReport = `Report Prêmio BCOP 2024 ${formatLeaderboardTypeForReport}.csv`

  return (
    <div className="inline-block min-w-full py-2 align-middle">
      <div className="-mt-3">
        <div className="text-sm grid grid-cols-2 text-gray-600 gap-x-3 my-2">
          <div className="col-start-3 flex flex-col leading-tight">
            <CSVReportDownload
              data={leaderboardGeralData}
              headers={headers}
              filename={filenameReport}
              target={"_blank"}
              separator={";"}
              uFEFF={true}
              enclosingCharacter='"'
              asyncOnClick={false}
              onClick={() => {}}
            >
              <span className="inline-flex items-center h-9 px-3 text-right text-sm rounded-full font-semibold focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
                <DownloadIcon className="h-5 w-5 mr-2 flex-none" />
                Download Report
              </span>
            </CSVReportDownload>
          </div>
        </div>

        <LeaderBoardTable
          data={leaderboardGeralData}
          loading={isLoading}
          leaderboard={leaderboard}
          availableAutonoumsSystem={availableAutonoumsSystem}
        />
      </div>
    </div>
  )
}

export default LeaderBoardGeral
