import React, { ReactElement, useEffect, useState } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import LeaderBoardGeral from "@components/leaderboard/leaderBoard-geral"
import LeaderBoardPessoal from "@components/leaderboard/leaderBoard-pessoal"
import { DELETE_ALERT } from "@store/navigation-store"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { PageDocsSidebarContentGeneric } from "@models/page-docs-sidebar-content-type"
import { PageMetadataObject } from "@models/page-metadata-type"
import ScrollTop from "@components/shared/scroll-top"
import { LeaderboardType } from "@components/leaderboard/leaderBoard-model"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery } from "urql"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
  pageMetadata: PageMetadataObject
}

interface ReducerSelected {
  availableAutonomousSystems: AutonomousSystem[] | null
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const LeaderBoardPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { availableAutonomousSystems } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
    }
  })

  const [leaderBoardType, setLeaderBoardType] =
    useState<LeaderboardType>("IMPLANTACAO")

  const { initialized: isKeycloakInitialized } = useKeycloak()

  const [resultLeaderboardGeral, _] = useQuery({
    query: `
      query($leaderboardType: LeaderBoardType!){
        leaderboardGeral(leaderboardType: $leaderboardType) {
          lastUpdate
          nodes {
            posicao
            asn
            name
            porcentagemAtual
            conformeRegulamentoMedicoes
            conformeRegulamentoSimetISP
          }
        }
      }
    `,
    variables: {
      leaderboardType: leaderBoardType,
    },
    pause: !isKeycloakInitialized,
  })

  const {
    data: leaderBoardGeralData,
    fetching: fetchingLeaderboardGeral,
    error,
  } = resultLeaderboardGeral

  return (
    <>
      <Helmet>
        <title>PAS - Desafio BCOP</title>
      </Helmet>
      <RequireAuthentication className="min-h-full relative">
        <ScrollTop />
        <div className="pt-6 px-4 sm:px-6 lg:px-8 h-full max-w-5xl">
          <div className="contents">
            <div className="flex flex-col">
              {asn !== undefined && <LeaderBoardPessoal asn={asn} />}

              <div className="container h-16"></div>
              <div className="border-1 border-t border-gray-400"></div>
              <div className="container h-16"></div>

              <span className="text-xl font-semibold text-black">
                Ranking dos sistemas autônomos
              </span>
              <span className="inline-flex items-center pb-8 pt-8">
                <button
                  type="button"
                  onClick={() => setLeaderBoardType("IMPLANTACAO")}
                  className={classNames(
                    leaderBoardType === "IMPLANTACAO"
                      ? "bg-blue-500 text-white font-bold"
                      : "bg-white text-gray-900 font-semibold hover:bg-gray-100",
                    "relative inline-flex items-center rounded-l-md px-16 py-3 text-sm font-bold  ring-1 ring-inset ring-gray-300 focus:z-10"
                  )}
                >
                  Prêmio 1
                </button>
                <button
                  type="button"
                  onClick={() => setLeaderBoardType("ACRESCIMO")}
                  className={classNames(
                    leaderBoardType === "ACRESCIMO"
                      ? "bg-blue-500 text-white font-bold"
                      : "bg-white text-gray-900 font-semibold hover:bg-gray-100",
                    "relative -ml-px inline-flex items-center rounded-r-md px-16 py-3 text-sm ring-1 ring-inset ring-gray-300 focus:z-10"
                  )}
                >
                  Prêmio 2
                </button>
              </span>

              <LeaderBoardGeral
                availableAutonoumsSystem={availableAutonomousSystems}
                leaderboard={leaderBoardType}
                leaderboardGeralData={
                  leaderBoardGeralData?.leaderboardGeral?.nodes || []
                }
                isLoading={!isKeycloakInitialized && !fetchingLeaderboardGeral}
              />
            </div>
          </div>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default LeaderBoardPage

export const getPageSidebarContent = (): PageDocsSidebarContentGeneric => {
  return {
    title: "Gerenciamento de servidores",
    abstract:
      "Gerenciar os servidores Simet instalados na rede do seu sistema autônomo",
    content: <div></div>,
  }
}
