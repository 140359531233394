import { useKeycloak } from "@react-keycloak/web"
import React, { FunctionComponent } from "react"
import { useQuery } from "urql"
import { parseISO, format } from "date-fns"
import { LeaderboardPessoalDataType } from "./leaderBoard-model"

interface Props {
  asn: number
}

const LeaderBoardPessoal: FunctionComponent<Props> = ({ asn }) => {
  const { initialized: isKeycloakInitialized } = useKeycloak()

  const [result, _] = useQuery({
    query: `
      query($asn: Int!){
        leaderboardPessoal(asn: $asn) {
          lastUpdate
          nodes {
            asn
            name
            porcentagemBase
            porcentagemAtual
            conformeRegulamento
            medicaoSpoofadaV4
            medicaoSpoofadaV6
            medicaoDNSV4
            medicaoDNSV6
            medicaoAclDNSV4
            medicaoAclDNSV6
            categoriaPremio
          }
        }
      }
    `,
    variables: {
      asn: asn,
    },
    pause: !isKeycloakInitialized,
  })

  const { data, fetching, error } = result

  if (error) console.error(error)
  let ultimaAtualizacao = undefined

  if (!fetching && data?.leaderboardPessoal.lastUpdate !== undefined) {
    ultimaAtualizacao = parseISO(data?.leaderboardPessoal.lastUpdate)
  }

  const premio_1 = (
    data?.leaderboardPessoal?.nodes as LeaderboardPessoalDataType[]
  )
    ?.filter((leaderboard) => leaderboard?.categoriaPremio == "premio_1")
    ?.at(0)

  const premio_2 = (
    data?.leaderboardPessoal?.nodes as LeaderboardPessoalDataType[]
  )
    ?.filter((leaderboard) => leaderboard?.categoriaPremio == "premio_2")
    ?.at(0)

  return (
    <>
      {/* <div className="flex items-center justify-center"> */}
      <div className="flex justify-between items-start text-slate-900 mt-4">
        <div className="text-xl font-semibold">Pontuação do AS{asn}</div>
        <div className="text-sm">
          Última Atualização:{" "}
          {ultimaAtualizacao ? format(ultimaAtualizacao, "dd-MM-yyyy") : ""}
        </div>
      </div>
      <div className="flex flex-col items-center justify-start mt-8">
        <div className="flex items-start justify-start self-stretch">
          <div className="flex w-1/3 flex-col items-start justify-center px-2.5"></div>
          <div className="flex w-1/3 flex-col items-center justify-center = px-2.5">
            <div className="text-xl font-bold text-slate-900">Prêmio 1</div>
          </div>
          <div className="flex w-1/3 flex-col items-center justify-center px-2.5">
            <div className="text-xl font-bold text-slate-900">Prêmio 2</div>
          </div>
        </div>
        <div className="flex items-start justify-start self-stretch mt-3">
          <div className="flex w-1/3 h-16 flex-col items-start justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            <div className="text-base font-bold text-slate-900">
              Implantação de IPv6
            </div>
          </div>
          <div className="flex w-1/3 h-16 flex-col items-center justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            <div className="text-base font-bold text-slate-900">
              {premio_1?.porcentagemBase || ""}%
              <span className="align-top text-sm text-slate-600">&#42;</span>
            </div>
          </div>
          <div className="flex w-1/3 h-16 flex-col items-center justify-center bg-slate-100 px-2.5">
            <div className="text-base font-bold text-slate-900">
              {premio_2?.porcentagemBase || ""}%
              <span className="align-top text-sm text-slate-600">
                &#42;&#42;
              </span>
            </div>
          </div>
        </div>
        <div className="flex h-14 items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500  px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                DNS recursivo{" "}
              </span>
              <span className="text-slate-900 text-base font-medium">
                (IPv4)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500  px-2.5 font-bold text-slate-900">
            {premio_1?.medicaoDNSV4 != undefined ? (
              premio_1.medicaoDNSV4 ? (
                -10
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center  px-2.5">
            <div className="text-base font-bold text-slate-900">
              {premio_2?.medicaoDNSV4 != undefined ? (
                premio_2.medicaoDNSV4 ? (
                  -10
                ) : (
                  <div className="relative h-6 w-6 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="3"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </div>
                )
              ) : (
                <>--</>
              )}
            </div>
          </div>
        </div>
        <div className="flex h-14 w-[730px] items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                DNS recursivo
              </span>
              <span className="text-slate-900 text-base font-medium">
                {" "}
                (IPv6)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500 bg-slate-100 px-2.5 font-bold text-slate-900">
            {premio_1?.medicaoDNSV6 != undefined ? (
              premio_1.medicaoDNSV6 ? (
                -10
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center bg-slate-100 px-2.5 font-bold text-slate-900">
            {premio_2?.medicaoDNSV6 != undefined ? (
              premio_2.medicaoDNSV6 ? (
                -10
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="flex w-[730px] items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500 bg-neutral-50 px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                Filtro do DNS recursivo{" "}
              </span>
              <span className="text-slate-900 text-base font-medium">
                (IPv4)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500 bg-neutral-50 px-2.5">
            {premio_1?.medicaoAclDNSV4 != undefined ? (
              premio_1.medicaoAclDNSV4 ? (
                -5
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center bg-neutral-50 px-2.5">
            {premio_2?.medicaoAclDNSV4 != undefined ? (
              premio_2.medicaoAclDNSV4 ? (
                -5
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="flex w-[730px] items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                Filtro do DNS recursivo{" "}
              </span>
              <span className="text-slate-900 text-base font-medium">
                (IPv6)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            {premio_1?.medicaoAclDNSV6 != undefined ? (
              premio_1.medicaoAclDNSV6 ? (
                -5
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center bg-slate-100 px-2.5">
            {premio_2?.medicaoAclDNSV6 != undefined ? (
              premio_2.medicaoAclDNSV6 ? (
                -5
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="flex w-[730px] items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500  px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                Filtro de pacotes spoofados{" "}
              </span>
              <span className="text-slate-900 text-base font-medium">
                (IPv4)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500  px-2.5">
            <div className="text-base font-bold text-slate-900">
              {premio_1?.medicaoSpoofadaV4 != undefined ? (
                premio_1.medicaoSpoofadaV4 ? (
                  -10
                ) : (
                  <div className="relative h-6 w-6 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="3"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </div>
                )
              ) : (
                <>--</>
              )}
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center  px-2.5">
            <div className="text-base font-bold text-slate-900">
              {premio_2?.medicaoSpoofadaV4 != undefined ? (
                premio_2.medicaoSpoofadaV4 ? (
                  -10
                ) : (
                  <div className="relative h-6 w-6 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="3"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </div>
                )
              ) : (
                <>--</>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-[730px] items-start justify-start self-stretch">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            <div>
              <span className="text-slate-900 text-base font-bold">
                Filtro de pacotes spoofados{" "}
              </span>
              <span className="text-slate-900 text-base font-medium">
                (IPv6)
              </span>
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-r border-slate-500 bg-slate-100 px-2.5">
            {premio_1?.medicaoSpoofadaV6 != undefined ? (
              premio_1.medicaoSpoofadaV6 ? (
                -10
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center bg-slate-100 px-2.5">
            {premio_2?.medicaoSpoofadaV6 != undefined ? (
              premio_2.medicaoSpoofadaV6 ? (
                -10
              ) : (
                <div className="relative h-6 w-6 text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              )
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="flex w-[730px] items-start justify-start self-stretch bg-gray-400">
          <div className="flex h-14 w-1/3 flex-col items-start justify-center border-t-2 border-slate-500 bg-slate-400 px-2.5">
            <div className="text-base font-bold text-slate-900">
              Pontuação do AS
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-l border-r border-t-2 border-slate-500 bg-slate-400 px-2.5">
            <div className="text-xl font-bold text-slate-900">
              {premio_1?.porcentagemAtual || ""}%
            </div>
          </div>
          <div className="flex h-14 w-1/3 flex-col items-center justify-center border-t-2 border-slate-500 bg-slate-400 px-2.5">
            <div className="text-xl font-bold text-slate-900">
              {premio_2?.porcentagemAtual || ""}%
            </div>
          </div>
        </div>
        <div className="mt-8 w-full text-sm space-y-1.5 leading-tight">
          <div className="ml-2">
            * <span className="font-semibold">Prêmio 1</span>: Implantação do
            protocolo IPv6, independente de quando esta implantação ocorreu.
          </div>
          <div>
            ** <span className="font-semibold">Prêmio 2</span>: Implantação nova
            do protocolo IPv6, ocorrida durante o Desafio BCOP (set/2024 a
            nov/2024).
          </div>
          <div className="pt-2">
            A implantação do protocolo IPv6 é medida como a porcentagem das
            medições SIMET com IPv6 funcionando corretamente em relação ao total
            das medições SIMET.
          </div>
          <div>
            A atualização do desafio ocorre nas segundas-feiras. Durante o mês
            de novembro e para a premiação, são consideradas todas as medições
            do mês de novembro, tanto para calcular a implantação do protocolo
            IPv6, quanto para verificar a conformidade com as práticas BCOP (DNS
            recursivo, filtragem de spoofing). Durante os meses setembro e
            outubro são consideradas somente as medições da semana anterior,
            para demonstrar o progresso do provedor.
          </div>
          <div>
            O servidor DNS recursivo é identificado com medições dos medidores
            SIMET Linux e SIMET Android. Pelo menos uma medição no período de
            avaliação deve demonstrar um servidor DNS recursivo próprio para
            IPv4 e para IPv6. Senão ocorre o desconto de penalidade (-10
            pontos).
          </div>
          <div>
            O servidor DNS recursivo é identificado como "aberto" quando
            responde uma consulta DNS enviada do AS22548. Se a última consulta
            DNS IPv4 ou IPv6 do período de avaliação for respondida ocorre o
            desconto de penalidade (-5 pontos).
          </div>
          <div>
            A filtragem de pacotes spoofados é testada com pacotes cujo endereço
            de origem é forjado. Os pacotes são enviados tanto pelo medidor
            SIMET Linux ao servidor SIMET do provedor, quanto pelo servidor
            SIMET do provedor a um destino no AS22548. Ou seja, verifica-se
            filtragem na rede de acesso e na borda do sistema autônomo. Se um
            pacote IPv4 ou IPv6 for recebido no destino, durante o período de
            avaliação, ocorre o desconto de penalidade (-10 pontos). Pacotes que
            atravessaram NAT são desconsiderados.
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default LeaderBoardPessoal
